import React, { Component } from 'react';
import { Button, Divider, Grid, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import '../App.css';

import ConstValues from '../utils/ConstValues';
import Preloader from '../components/Preloader';

export default class FinalQuiz extends Component {

    constructor() {
        super();
        this.state = {
            manager: '',
            loader: false
        }

        this.exit = this.exit.bind(this);
        this.goWeb = this.goWeb.bind(this);
        this.goContact = this.goContact.bind(this);
    }

    componentDidMount() {
        this.setState({ loader: false });
        let id_evaluation = this.props.params.id;
        this.getManagerEvaluation(id_evaluation);
    }

    getManagerEvaluation(id) {
        fetch(ConstValues.SERVICE_CONNECT + 'evaluation/manager/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then((data) => {
                if (data['status'] === 1) {
                    let manager = data['text'];
                    this.setState({ manager, loader: true });
                }
            })
            .catch(console.error)
    }

    exit() {
        window.open('https://www.google.com/', '_self');
        // customWindow.close();
    }

    goWeb() {
        window.open('https://www.grupotoledo.ec/', "_self");
    }

    goContact() {
        let message = "Saludos  "+this.state.manager.nombre+", mi nombre es "+this.state.manager.evaluado+' necesito información con un consentimiento generado';
        let link_wts = "https://wa.me/"+this.state.manager.telefono+"?text="+message;
        window.open(link_wts, '_blank');
        // window.open('https://www.grupotoledo.ec/contactanos.html', "_self");
    }

    render() {
        let isMobile = false;
        if (window.screen.width < 480) {
            isMobile = true;
        }
        
        if (this.state.loader) {
            // let image = ConstValues.IMAGE_COMPANY + this.props.params.id + '.jpg';
            let image = ConstValues.IMAGE_COMPANY + this.state.manager.id_empresa + '.jpg';
            return (
                <div className="container-principal">
                    <div style={{ textAlign: 'center' }}>
                        <img src={image} alt="photo" height="100" />
                    </div>
                    <div style={{ fontSize: 15, marginBottom: 50, textAlign: 'center' }}>
                        <span style={{ fontWeight: '300' }}>Proceso realizado por: </span>
                        <span style={{ fontWeight: 'bold', fontSize: 17 }}>Grupo </span>
                        <span style={{ fontWeight: '300', fontSize: 17 }}>Toledo</span>
                    </div>
                    <div className="title-size margin-reduce">¡Muchas gracias!</div>

                    <div className="description">
                        En los próximas horas, un consultor del equipo de
                        Grupo Toledo tomará contacto contigo para continuar con
                        el proceso. Es importante recordar que toda tu
                        información personal recopilada es tratada como
                        información confidencial. Agradecemos tu tiempo.
                    </div>
                    <Divider style={{ marginTop: 30, marginBottom: 20 }} />
                    <div>
                        <Grid container>
                            <Grid item xs={2}>
                                <IconButton color="success" onClick={this.exit}>
                                    <CheckIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <div className='text-btn-final'>Abandonar el sitio web.</div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={2}>
                                <IconButton color="success" onClick={this.goWeb}>
                                    <CheckIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <div className='text-btn-final'>Visitar sitio web de Grupo Toledo.</div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={2}>
                                <IconButton color="success" onClick={this.goContact}>
                                    <CheckIcon />
                                </IconButton>
                            </Grid>
                            <Grid item xs={10}>
                                <div className='text-btn-final'>Contactar a un consultor de Grupo Toledo.</div>
                            </Grid>
                        </Grid>
                        {/* <div>
                        {isMobile ? 
                        (
                            <div>
                                <Button className="button-final" variant="primary" onClick={this.goWeb}>Ir a página web de Grupo Toledo</Button>
                                <Button className="button-back" variant="secondary" onClick={this.exit}>Salir</Button>
                            </div>
                        )
                        :
                        (
                            <div>
                                <div style={{float: 'left'}}>
                                    <Button className="button-back" variant="secondary" onClick={this.exit}>Salir</Button>
                                </div>
                                <div style={{float: 'right'}}>
                                    <Button className="button-final" variant="primary" onClick={this.goWeb}>Ir a página web de Grupo Toledo</Button>
                                </div>
                            </div>
                        )
                        }
                        
                    </div> */}
                    </div>
                </div>
            )
        } else {
            return (<Preloader />)
        }
    }
}