import React, {Component} from 'react';
import { Grid } from '@mui/material';
import '../../App.css';

import ProgressQuest from '../ProgressQuest';

export default class Header extends Component{

    render(){
        const { percentage, count, total, image } = this.props;
        return(
          <div>
            <div style={{textAlign: 'left'}}>
                <span style={{fontWeight: 'bold'}}>Grupo </span>
                <span style={{fontWeight: '300', marginRight: 10}}>Toledo</span>
                <img src={image} alt="photo" height="30"/>
            </div>
            <ProgressQuest value={percentage}/>
            <div className="indicator-question">
                {/* <span style={{color: '#C9C8C8'}}>Pregunta </span>  */}
                {count} de {total}
            </div>
        </div>
        )
    }
}