export default class HelperMap {

  origin = '';

  constructor(){

  }

  setOrigin(origin){
    this.origin = origin;
  }

  getOrigin(){
    return this.origin;
  }
}