import React, { useRef, useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap';
import { TextField, Grid, MenuItem, Button, Fab, Tooltip, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import Preloader from '../Preloader';

const containerStyle = {
  width: '100%',
  height: '55vh'
};

// const center = {
//   lat: -3.745,
//   lng: -38.523
// };

const AppMap = ({zoom, center, seletPosition}) => {
  const mapRef = useRef(null);
  const [showLoader, setShowLoader] = useState(false)
  const [position, setPosition] = useState({
    lat: -0.1766053,
    lng: -78.4806785
  });

  function handleLoad(map) {
    mapRef.current = map;
  }

  function handleCenterChanged() {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  function handleDragEnd() {
    seletPosition(position);
  }

  useEffect(() => {
    if(navigator.geolocation && (center == '' || center == null)){
      setShowLoader(true);
      const options = {
        enableHighAccuracy: false,
        timeout: 15000,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        item => {
          let newPosition = { 
            lat: item.coords.latitude, 
            lng: item.coords.longitude
          }
          center = newPosition;
          setShowLoader(false);
          seletPosition(newPosition);
        }, 
        error => {
          console.log(error);
          setShowLoader(false);
          toast.error('No se pudo recuperar la ubicación de su dispositivo, desliza el dedo sobre el mapa y seleccione su ubicación manualmente');
          center = {
            lat: -0.1766053,
            lng: -78.4806785
          }
        },
        options
      )
    }else{
      center = {
        lat: -0.1766053,
        lng: -78.4806785
      }
    }
}, [center]);
  
  console.log(zoom, center);
  // if(center){
  return (
    <div>
      {showLoader &&
        <div style={{display: 'flex', justifyContent: 'right'}}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      }
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={handleLoad}
        onCenterChanged={handleCenterChanged}
        onDragEnd={handleDragEnd}
        zoom={zoom}
        center={center}
        id="map"
      >
        {position ? <Marker position={position} />: <Marker position={center} />}
      </GoogleMap>
    </div>
  );
//   }else{
//     return <Preloader/>;
//   }
}

export default AppMap;