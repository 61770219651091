import React, {Component} from 'react';
import { TextField, Grid, MenuItem, Button, InputAdornment, Autocomplete, Box } from '@mui/material';

import '../../App.css';

import HelperMap from '../../utils/HelperMap';
import Preloader from '../Preloader';
import AppMap from './AppMap';
import { toast } from 'react-toastify';

export default class FormPerson extends Component{

  constructor(props){
    super(props);
    this.state = {
      data: '',
      address: '',
      origin: 'L',
      colorOriginP: {backgroundColor: '#f3f3f3', color: '#000'},
      colorOriginL: {backgroundColor: '#000', color: '#fff'},
      error: {},
      blockButton: false,
      mapRef: '',
      showMap: false,
      countries: []
    }
    this.leafletMap = null;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this);
    this.nextValidate = this.nextValidate.bind(this);
    this.searchLocationCoordinates = this.searchLocationCoordinates.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.helperMap = new HelperMap();
  }

  componentDidMount(){
    let data = this.props.data;
    let keys = Object.keys(data);
    let error = {...this.state.error};
    
    for (let index = 0; index < 8; index++) {
      let field = keys[index];
      error[field] = {error: false, text: '', required: true}
    }
    console.log(error);
    // data.position = center;
    this.setState({data, error, countries: this.props.countries});
    this.geocoder = new window.google.maps.Geocoder();
    if(this.props.countQuestions == 3){
      this.loadMap();
      // let interval = setInterval(() => {
      //     this.setState({ showMap: true })
      //     clearInterval(interval)
      // }, 1000)
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let data = {...this.state.data};
    console.log(name, value);
    if(name === 'birthday' || name == 'email'){
      data[name] = value;
    }else{
      data[name] = value.replace(/[^\w\s]/gi, '');
    }
    if(name === 'name' || name == 'lastname'){
      data[name] = data[name].toUpperCase();
    }
    this.setState({data, blockButton: false});
  }

  handleInputBlur(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.validField(name, value);
    if(name === 'email'){
      this.validFieldEmail(name, value);
    }

    if(name === 'phone'){
      this.validFieldPhone(name, value);
    }
  }

  handleInputKeyDown(event) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = event.keyCode;
    if(name === 'phone'){
      if (key != 8 && (key < 46 || key > 57) && (key < 96 || key > 105)){
        event.preventDefault();
      };
    }
  }

  handleAutoCompleteChange(value) {
    console.log('autocomplete',value);
    let data = {...this.state.data};
    data['country'] = value;
    this.setState({data, blockButton: false});
  }

  validEmail(email){
    let result = true;
    let expr = /^([a-zA-Z0-9_\.\-])+\@(([a-zA])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if(!expr.test(email)){
      result = false;
    }else{
      let arrayEmail = email.split('@');
      if(arrayEmail[1].split('.').length > 4){
        result = false;
      }
    }
    return result;    
  }
  
  setOrigin = (origin) => () => {
    let colorOriginL = {backgroundColor: '#f3f3f3', color: '#000'};
    let colorOriginP = {backgroundColor: '#f3f3f3', color: '#000'};
    if(origin === 'L'){
      colorOriginL = {backgroundColor: '#000', color: '#fff'};
      colorOriginP = {backgroundColor: '#f3f3f3', color: '#000'};
    }else{
      colorOriginP = {backgroundColor: '#000', color: '#fff'};
      colorOriginL = {backgroundColor: '#f3f3f3', color: '#000'};
    }
    this.setState({origin, colorOriginL, colorOriginP});
  } 

  nextValidate() {
    if(this.validateFields()){
      this.props.next(this.state.data);
    }
  }

  validateFields(){
    let keys = Object.keys(this.state.data);
    let countErrors = 0;
    if(this.props.countQuestions === 1){
      for (let index = 0; index < 5; index++) {
        let field = keys[index];
        let error = this.validField(field, this.state.data[field]);
        if(error[field].error){
          countErrors++;
        }
      }
      if(this.state.data.country == ''){
        countErrors++;
        toast.error("El campo nacionalidad esta vacío")
      }
    }

    if(this.props.countQuestions === 2){
      for (let index = 5; index < 7; index++) {
        let field = keys[index];
        let error = this.validField(field, this.state.data[field]);
        if(field === 'email'){
          error = this.validFieldEmail(field, this.state.data[field]);
        }
    
        if(field === 'phone'){
          error = this.validFieldPhone(field, this.state.data[field]);
        }
        if(error[field].error){
          countErrors++;
        }
      }
    }

    let result = true;
    if(countErrors > 0){
      result = false;
    }
    return result;
  }

  validField(name, value){
    let error = {...this.state.error};
    console.log(name, value);
    if(error[name].required && (value === '' || value === 0)){
      error = this.messageField(error, name, true, 'Campo obligatirio');
    }else{
      error = this.messageField(error, name, false, '');
    }
    this.setState({error});
    return error;
  }

  validFieldEmail(name, value){
    let error = {...this.state.error};
    if(value != ''){
      if(!this.validEmail(value)){
        error = this.messageField(error, name, true, 'El correo electronico es incorrecto');
      }else{
        error = this.messageField(error, name, false, '');
      }
      this.setState({error});
    }
    return error;
  }

  validFieldPhone(name, value){
    let error = {...this.state.error};
    if(value != ''){
      if(value.startsWith('9', 0)){
        if(value.length != 9){
          error = this.messageField(error, name, true, 'El número ingresado debe tener 9 dígitos');
        }else{
          error = this.messageField(error, name, false, '');
        }
      }else{
        error = this.messageField(error, name, true, 'Ingresar el numero omitiendo el 0 al comienzo');
      }
    }
    return error;
  }

  messageField(error, name, isError, text){
    error[name].error = isError;
    error[name].text = text;
    this.setState({blockButton: isError});
    return error;
  }

  loadMap() {
    if(navigator.geolocation){
      this.setState({blockButton: true});
      navigator.geolocation.getCurrentPosition(item => {
        let data = {...this.state.data}
        data.position = { 
          lat: item.coords.latitude, 
          lng: item.coords.longitude
        }
        this.setState({data})
        let search = { location: data.position }
        this.searchLocation('P', search);
      })
    }
  }
  
  onUnmountMap = (map) => () => {
    map = null;
    this.setState({mapRef: map});
  }

  searchLocationAddress = () => () => {
    let search = { address: this.state.data.address }
    this.searchLocation('A', search);
  }

  searchLocationCoordinates(positionSelect) {
    let data = {...this.state.data}
    data.position = positionSelect;
    this.setState({data})
    let search = { location: data.position }
    this.searchLocation('P', search);
  }

  searchLocation(origin, search) {
    this.geocoder.geocode(search, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        let data = {...this.state.data}
        // if(origin == 'A'){
        //   data.position = { 
        //     lat: results[0].geometry.location.lat(), 
        //     lng: results[0].geometry.location.lng()
        //   }
        // }else{
          data.address = results[0].formatted_address;
        // }
        this.setState({data, blockButton: false})
      }
      // else{
      //   toast.error("La direccion no ha sido reconocida, por favor ingrese una direccion cercana y de click en el mapa en su ubicación")
      // }
    })
  }

  

  render(){
    const { data, countQuestions, next, back } = this.props;

    if(this.state.data){
      return(
        <div>
          {countQuestions === 1 &&
            <div style={{marginTop: 50}}>
              <TextField 
                name="name" 
                label='Nombre completo'
                value={this.state.data.name} 
                error={this.state.error.name.error}
                helperText={this.state.error.name.error ? this.state.error.name.text : ''}
                required={this.state.error.name.required}
                onChange={(event) => this.handleInputChange(event)}
                onBlur={this.handleInputBlur} 
                margin="normal"
                fullWidth
              />
              <TextField 
                name="lastname" 
                label='Apellidos completo'
                value={this.state.data.lastname} 
                error={this.state.error.lastname.error}
                helperText={this.state.error.lastname.error ? this.state.error.lastname.text : ''}
                required={this.state.error.lastname.required}
                onChange={(event) => this.handleInputChange(event)}
                onBlur={this.handleInputBlur} 
                margin="normal"
                fullWidth
              />
              <Grid container spacing={2}>
                <Grid item md={6} xs={6} style={{textAlign: 'left'}}>
                  <TextField 
                    select
                    name="document_type" 
                    label='Tipo de documento'
                    value={this.state.data.document_type}
                    error={this.state.error.document_type.error}
                    helperText={this.state.error.document_type.error ? this.state.error.document_type.text : ''}
                    required={this.state.error.document_type.required} 
                    onChange={(event) => this.handleInputChange(event)}
                    onBlur={this.handleInputBlur} 
                    margin="normal"
                    fullWidth
                  >
                    <MenuItem value="Cédula">Cédula</MenuItem>
                    <MenuItem value="Pasaporte">Pasaporte</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={6} xs={6}>
                  <TextField 
                    name="document" 
                    label='N de identificación'
                    value={this.state.data.document} 
                    error={this.state.error.document.error}
                    helperText={this.state.error.document.error ? this.state.error.document.text : ''}
                    required={this.state.error.document.required}
                    onChange={(event) => this.handleInputChange(event)}
                    onBlur={this.handleInputBlur} 
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <TextField 
                type="date" 
                name="birthday" 
                label='Fecha de nacimiento'
                value={this.state.data.birthday} 
                error={this.state.error.birthday.error}
                helperText={this.state.error.birthday.error ? this.state.error.birthday.text : ''}
                required={this.state.error.birthday.required}
                onChange={(event) => this.handleInputChange(event)}
                onBlur={this.handleInputBlur} 
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: true}}
              />
              <Autocomplete
                id="country"
                name="country"
                options={this.state.countries}
                autoHighlight
                fullWidth
                error
                onChange={(event, newValue) => {
                  this.handleAutoCompleteChange(newValue);
                }}
                getOptionLabel={(option) => option.nombre}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {option.nombre} ({option.iso2})
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nacionalidad"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    
                    margin="normal"
                    fullWidth
                  />
                )}
              />
              <TextField 
                select
                name="gender" 
                label='Genero'
                value={this.state.data.gender} 
                onChange={(event) => this.handleInputChange(event)} 
                margin="normal"
                fullWidth
            >
                <MenuItem value="Mujer">Mujer</MenuItem>
                <MenuItem value="Hombre">Hombre</MenuItem>
                <MenuItem value="Prefiero no detallar">Prefiero no detallar</MenuItem>
            </TextField>
            </div>
          }
          {countQuestions === 2 &&
            <div style={{marginTop: 50}}>
              <TextField 
                name="phone" 
                label='Teléfono celular'
                value={this.state.data.phone} 
                error={this.state.error.phone.error}
                helperText={this.state.error.phone.error ? this.state.error.phone.text : ''}
                required={this.state.error.phone.required}
                onChange={(event) => this.handleInputChange(event)}
                onBlur={this.handleInputBlur} 
                onKeyDown={this.handleInputKeyDown}
                margin="normal"
                inputProps={{
                  maxLength: 9,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+593</InputAdornment>,
                }}
                fullWidth
              />
              <TextField 
                name="email" 
                label='Correo electrónico'
                value={this.state.data.email} 
                error={this.state.error.email.error}
                helperText={this.state.error.email.error ? this.state.error.email.text : ''}
                required={this.state.error.email.required}
                onChange={(event) => this.handleInputChange(event)}
                onBlur={this.handleInputBlur} 
                margin="normal"
                fullWidth
              />
            </div>
          }
          {countQuestions === 3 &&
            <div style={{marginTop: 20}}>
              <div style={{height: '100%', width: '100%'}}>
                <AppMap 
                  zoom={18}
                  center={this.state.data.position}
                  seletPosition={this.searchLocationCoordinates}
                />
              </div>
            </div>
          }
          {countQuestions === 4 &&
            <div style={{marginTop: 50}}>
              <TextField 
                name="address" 
                label='Ubicación'
                value={this.state.data.address} 
                onChange={(event) => this.handleInputChange(event)}
                onKeyDown={this.handleInputKeyDown} 
                margin="normal"
                fullWidth
              />
              <TextField 
                name="reference" 
                label='Referencia'
                value={this.state.data.reference} 
                onChange={(event) => this.handleInputChange(event)}
                onKeyDown={this.handleInputKeyDown} 
                margin="normal"
                fullWidth
              />
              <TextField 
                name="neighborhood" 
                label='Barrio'
                value={this.state.data.neighborhood} 
                onChange={(event) => this.handleInputChange(event)}
                onKeyDown={this.handleInputKeyDown} 
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <TextField 
                name="parish" 
                label='Parroquia'
                value={this.state.data.parish} 
                onChange={(event) => this.handleInputChange(event)} 
                onKeyDown={this.handleInputKeyDown}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 50 }}
              />
              <TextField 
                name="sector" 
                label='Sector'
                value={this.state.data.sector} 
                onChange={(event) => this.handleInputChange(event)} 
                onKeyDown={this.handleInputKeyDown}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 200 }}
              />
            </div>
          }
          <div style={{marginTop: 30}}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Button variant="contained" size='large' 
                  onClick={() => {
                    this.nextValidate();
                  }}
                  className="button-contained"
                  disabled={this.state.blockButton}
                >
                  Continuar
                </Button>
              </Grid>
              {/* {countQuestions > 1 && (
                <Grid item md={6} xs={12}>
                  <Button variant="outlined" size='large' onClick={back()}
                    className="button-outlined"
                    disabled={this.state.blockButton}
                  >
                    Regresar
                  </Button>
                </Grid>
              )} */}
            </Grid>
          </div>
        </div>
      )
    }else{
      return (<Preloader/>);
    }
  }
}