import React, {Component} from 'react';
import {Image} from 'react-bootstrap';
import CanvasDraw from '@win11react/react-canvas-draw';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Grid, Button, FormControlLabel, Checkbox, Typography, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Resizer from "react-image-file-resizer";
import '../App.css';

import * as questionsJson from '../assets/questions.json';
import ProgressQuest from '../components/ProgressQuest';
import Preloader from '../components/Preloader';
import ConstValues from '../utils/ConstValues';
import Header from '../components/quiz/Header';
import FormPerson from '../components/quiz/FormPerson';
import Terms from './Terms';
import Consent from './Consent';

import user_default from '../assets/user_default.png';

const saveSvgAsPng = require('save-svg-as-png');

export default class Quiz extends Component{

    constructor(){
        super();
        this.state={
            visible: [true, false, false, false, false, false, false, false],
            loader: false,
            questions: [],
            countQuestionsShow: 0,
            percentageQuestions: 0,
            consentData: '',
            points: [],
            loading: false,
            disabledButton: true,
            signatureDraw: '',
            signatureSave: '',
            imageSaved: '',
            showCam: false,
            imagesCaptures: '',
            imagePreviewUrl: '',
            imageProcess: false,
            evaluation: '',
            terminos: false,
            consent: false,
            labelBtnPhoto: 'Tomar foto',
            countries: [],
            data: {
                name: '',
                lastname: '',
                document_type: 'Cédula',
                document: '',
                birthday: '',
                phone: '',
                email: '',
                country: '',
                address: '',
                position: '',
                reference: '',
                company: '',
                id_company: '',
                id_evaluation: '',
                gender: '',
                neighborhood: '',
                parish: '',
                sector: ''
            }
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.countQuestions = 0;
        this.numberQuestion = 8;
        this.saveableCanvas = '';
        this.webcam = '';
    }

    componentDidMount(){
        this.setState({
            questions: questionsJson.default, 
            countQuestionsShow: 1
        })
        this.getCountries();
    }

    getCountries(){
        fetch(ConstValues.SERVICE_CONNECT+'person/countries', {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then((data) => {
            console.log(data);
            if(data['status'] === 1){
                this.setState({countries: data['text']})
            }
            this.getEvaluation(this.props.params.id);
        })
        .catch(console.error)
    }

    getEvaluation(id){
        fetch(ConstValues.SERVICE_CONNECT+'evaluation/'+id, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json'
            }
         })
        .then(res => res.json())
        .then((data) => {
            if(data['status'] === 1){
                let evaluation = data['text'];
                let dataConsent = {...this.state.data};
                dataConsent.id_evaluation = id;
                dataConsent.company = evaluation.nombre_empresa;
                dataConsent.id_company = evaluation.id_empresa;
                this.setState({evaluation, data: dataConsent});
                this.getPerson(evaluation.idpersona);
                
            }
        })
        .catch(console.error)
    }

    getPerson(document){
        if(document !== undefined && document !== ''){
            fetch(ConstValues.SERVICE_CONNECT+'person/'+document, {
                method: 'GET',
                headers:{
                    'Content-Type': 'application/json'
                }
             })
            .then(res => res.json())
            .then((data) => {
                if(data['status'] === 1){
                    let person = data['text'];
                    let dataConsent = {...this.state.data};
                    let firtChartDocument = person.ci.substr(0, 1);
                    dataConsent.name = person.nombre;
                    dataConsent.lastname = person.apellido;
                    dataConsent.phone = person.telefono;
                    dataConsent.email = person.email;
                    dataConsent.document = person.ci;
                    dataConsent.document_type = 'Pasaporte';
                    if(person.ci.length == 10 && !isNaN(firtChartDocument)){
                        dataConsent.document_type = 'Cédula';
                    }
                    this.setState({data: dataConsent, loader: true});
                    this.calcultePercentage(1);
                }
            })
            .catch(console.error)
        }
    }

    calcultePercentage(value){
        let percentageQuestions = (value * 100)/this.numberQuestion;
        this.setState({percentageQuestions})

    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    } 

    goForm = () => () => {
        // this.nextQuestion();
        this.changeWindow(1);
    }

    saveContinue = (data) => {
        console.log(data);
        this.nextQuestion();
        this.setState({data});
        let next = this.countQuestions + 1;
        if(this.state.evaluation.solicitar_direccion){
            if(this.state.evaluation.solicitar_direccion.toString() == '1'){
                next = 5;
            }
        }
        this.changeWindow(next);
    }

    goSignature = () => () => {
        this.nextQuestion();
        this.changeWindow(6);
    }

    signature = () => () => {
        let signatureDraw = this.saveableCanvas.getSaveData();
        let line =  JSON.parse(signatureDraw);
        if(line.lines.length > 0){
            let pointsSVG = [];
            line.lines.forEach(data => {
                let pointsLines = "";
                data.points.forEach(point => {
                    pointsLines += point.x+","+point.y+" ";
                })
                pointsSVG.push(pointsLines);
            })
            this.setState({points: pointsSVG, signatureDraw: signatureDraw});
            saveSvgAsPng.svgAsPngUri(document.getElementById("svgsignature"))
            .then(uri => {
                this.setState({signatureSave: uri});
                this.nextQuestion();
                this.changeWindow(7);
            })
        }else{
            toast.error("Debe dibujar su firma")
        }
    }
    
    saveConsent = () => () => {
        if(this.state.imageSaved){
            this.setState({loading: true});
            let data = {
                consent: this.state.data,
                signature: this.state.signatureSave,
                photo: this.state.imageSaved,
    
            };
            console.log(data);
            axios.post(ConstValues.SERVICE_CONNECT+'quiz/send_consent', data)
            .then(res => {
                this.setState({loading: false});
                if(res.data.status ===  1){
                    toast.info(res.data.text);
                    this.props.navigate('/final/'+this.state.evaluation.id);
                }else{
                    toast.warning(res.data.text);
                }
            })
            .catch(error => {
                console.error(error);
            })
        }else{
            toast.error('Debe tomar o seleccionar una foto para finalizar');
        }
    }

    nextQuestion(){
        this.countQuestions++;
        let count = this.countQuestions + 1;
        this.setState({
            countQuestionsShow: count
        })
        this.calcultePercentage(count);
        return count;
    }

    backQuestion = () => () => {
        this.countQuestions--;
        let backCount = this.state.countQuestionsShow - 1;
        this.setState({
            countQuestionsShow: backCount
        });
        this.calcultePercentage(backCount);
        this.changeWindow(backCount);
    }

    changeWindow(index_select) {
        let visibleArray = this.state.visible;
        for (let index = 0; index < visibleArray.length; index++) {
          if(index === index_select){
            visibleArray[index] = true;
          }else{
            visibleArray[index] = false;
          }
        }
        this.setState({visible: visibleArray});
    }

    clearSignature = () => () => {
        this.saveableCanvas.clear();
    }

    handleImageChange = (event) => {
        let imageSaved = this.state.imageSaved;
        this.setState({imageProcess: true})
        try{
            Resizer.imageFileResizer(
                event.target.files[0],
                800,
                800,
                "JPEG",
                100,
                0,
                (uri) => {
                    imageSaved = uri;
                    this.capturePhoto(imageSaved);
                },
                "base64",
                400,
                400
            );
        } catch (err) {
            console.error(err);
        }
        
    }

    capturePhoto(imageSaved){
        if(this.state.data.document !== undefined && this.state.data.document !== ''){
            let data = {
                name_image: this.state.data.document,
                image: imageSaved
            };
            axios.post(ConstValues.SERVICE_CONNECT+'quiz/save_image', data)
            .then(res => {
                this.setState({imageSaved, disabledButton: false, imageProcess: false});
            })
            .catch(error => {
                console.error(error);
            })
        }else{
            toast.warning('El documento de identificacion no puede ser vacia')
        }
    }

    showImage(user_default){
        if(this.state.imageSaved === ''){
            return (<Image src={user_default} className="imagen"/>)
        }else{
            if(this.state.imageProcess){
                return (<CircularProgress color="success" />)
            }else{
                return(<Image src={this.state.imageSaved} className="imagen"/>)
            }
        }        
    }

    showHelpMap = () => () => {
        toast.warning('Si no se ha cargado tu ubicación, desliza el dedo sobre el mapa y selecciona tu ubicación manualmente.')
        toast.info('Para mejor control, utilice dos dedos para moverse y hacer zoom dentro del mapa')
    }

    render(){
        let imageSaved = '';
        if(this.state.imageSaved){
            imageSaved = ConstValues.IMAGE_CONNECT+'photos/'+this.state.data.document+'.png';
        }

        if(this.state.loader){
            const image = ConstValues.IMAGE_COMPANY+this.state.evaluation.id_empresa+'.jpg';
            return(
                <div>
                    {this.state.visible[0] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>Términos y condiciones para verificación de datos</div>
                                <div className='div-description'>
                                    <Terms/>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox value={this.state.terminos} onChange={this.handleInputChange} name="terminos" />
                                        }
                                        label={
                                            <Typography>Autorizo la realización de este estudio de confiabilidad</Typography> 
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{marginTop: 25}}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Button variant="contained" size='large'
                                            onClick={this.goForm()} 
                                            className="button-contained"
                                            disabled={!this.state.terminos}
                                        >
                                        Continuar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )}
                    {this.state.visible[1] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>Datos personales</div>
                                <div className='subtitle-section'>
                                    Todos los campos son obligatorios. Algunos campos
                                    ya tendrán información, asegúrate que la información
                                    sea correcta.
                                </div>
                            </div>
                            <FormPerson 
                                data={this.state.data}
                                countQuestions={this.state.countQuestionsShow} 
                                countries={this.state.countries}
                                next={this.saveContinue} 
                                back={this.backQuestion}
                            />
                        </div>
                    )}
                    {this.state.visible[2] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>Datos de contacto</div>
                                <div className='subtitle-section'>
                                    Todos los campos son obligatorios.
                                </div>
                            </div>
                            <FormPerson 
                                data={this.state.data}
                                countQuestions={this.state.countQuestionsShow} 
                                countries={this.state.countries}
                                next={this.saveContinue} 
                                back={this.backQuestion}
                            />
                        </div>
                    )}
                    {this.state.visible[3] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>
                                    Dirección domiciliaria
                                    <IconButton aria-label="delete" onClick={this.showHelpMap()}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </div>
                                <div className='subtitle-section'>
                                    Envia tu ubicación desde la puerta de tu domicilio
                                    para mejorar la precisión del mapa.
                                </div>
                            </div>
                            <FormPerson 
                                data={this.state.data}
                                countQuestions={this.state.countQuestionsShow} 
                                countries={this.state.countries}
                                next={this.saveContinue} 
                                back={this.backQuestion}
                            />
                        </div>
                    )}
                    {this.state.visible[4] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>Dirección domiciliaria</div>
                                <div className='subtitle-section'>
                                    Proporcionanos la información de tu domicilio para cotejar con la ubicación seleccionada en el mapa.
                                </div>
                            </div>
                            <FormPerson 
                                data={this.state.data}
                                countQuestions={this.state.countQuestionsShow}
                                countries={this.state.countries} 
                                next={this.saveContinue} 
                                back={this.backQuestion}
                            />
                        </div>
                    )}
                    {this.state.visible[5] && (
                        <div className="container-questions">
                            <Header 
                                percentage={this.state.percentageQuestions} 
                                count={this.state.countQuestionsShow} 
                                total={this.numberQuestion}
                                image={image}
                            />
                            <div style={{textAlign: 'left'}}>
                                <div className='title-section'>Consentimiento informado para el tratamiento de datos personales</div>
                                <div className='div-description'>
                                    <Consent datos={this.state.data}/>
                                </div>
                                <div style={{marginTop: 10}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox value={this.state.consent} onChange={this.handleInputChange} name="consent" />
                                        }
                                        label={
                                            <Typography>
                                                Acepta haber leído los  
                                                <a href='https://grupotoledo.ec/politica-privacidad.html' target='_blank' className='link-terms'>
                                                    &nbsp;terminos y condiciones&nbsp;
                                                </a> 
                                                de tratamiento de datos personales
                                            </Typography> 
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{marginTop: 25}}>
                                <Grid container spacing={2}>
                                    <Grid item md={6} xs={12}>
                                        <Button variant="contained" size='large'
                                            onClick={this.goSignature()}
                                            className="button-contained"
                                            disabled={!this.state.consent}
                                        >
                                        Continuar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    )}
                    {this.state.visible[6] && (
                    <div className="container-questions">
                        <Header 
                            percentage={this.state.percentageQuestions} 
                            count={this.state.countQuestionsShow} 
                            total={this.numberQuestion}
                            image={image}
                        />
                        <div style={{textAlign: 'left'}}>
                            <div className='title-section'>Firmar documento</div>
                            <div className='subtitle-section'>
                                No necesariamente debe ser exacta.
                            </div>
                        </div>
                        <div style={{marginTop: 30}}>
                            <div>
                                <CanvasDraw
                                    ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                                    brushColor='#000000'
                                    brushRadius={1}
                                    lazyRadius={5}
                                    canvasWidth={'100%'}
                                    canvasHeight={250}
                                    id="canvas_signature"
                                    style={{borderRadius: 10, border: 'solid 1px black'}}
                                    saveData={this.state.signatureDraw}
                                />

                                <svg id={'svgsignature'} height={250} width={600} style={{display: 'none'}}>
                                    {this.state.points.map((point, index) => (
                                        <polyline key={'P'+index} points={point} className="svg-signature" />
                                    ))}
                                </svg>
                            </div>
                        </div>
                        <div style={{marginTop: 20}}>
                            <Grid container spacing={2}>
                                <Grid item md={4} xs={12}>
                                    <Button variant="outlined" className="button-outlined" size='large'
                                        onClick={this.clearSignature()}>Reintentar firma
                                    </Button>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <Button variant="contained" size='large' onClick={this.signature()}
                                    className="button-contained">
                                        Continuar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    )}
                    {this.state.visible[7] && (
                    <div className="container-questions">
                        <Header 
                            percentage={this.state.percentageQuestions} 
                            count={this.state.countQuestionsShow} 
                            total={this.numberQuestion}
                            image={image}
                        />
                        <div style={{textAlign: 'left'}}>
                            <div className='title-section'>Validar identificación</div>
                            <div className='subtitle-section'>
                                Por favor, toma una fotografía frontal de tu rostro.
                            </div>
                        </div>
                        <div style={{marginTop: 30}}>
                            <div className="container-image">
                                {this.showImage(user_default)}
                            </div>
                        </div>
                        <div style={{marginTop: 30}}>
                            <label htmlFor="foto" style={{width: '100%'}}>
                                <input 
                                    type="file" 
                                    name="foto" id="foto" hidden accept="image/*" 
                                    capture="environment" onChange={(event) => this.handleImageChange(event)}
                                />
                                <div className='button-clear-signature'>
                                {this.state.imageSaved === ''? 'Tomar foto': 'Reintentar foto'}
                                </div>
                            </label>
                        </div>
                        {this.state.imageSaved !== '' &&
                            <div style={{marginTop: 20}}>
                                <Button variant="contained" size='large' 
                                    onClick={!this.state.loading ? this.saveConsent() : null}
                                    className="button-contained"
                                    disabled={this.state.loading}
                                >
                                    {this.state.loading ? 'Procesando…' : 'Finalizar'}
                                </Button>
                            </div>
                        }
                    </div>
                    )}
                </div>
            )
        }else{
            return (<Preloader/>)
        }
    }
}
