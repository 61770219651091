import React, {Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import WrapperComponent from '../utils/WrapperComponent';
import Wellcome from '../screens/Wellcome';
import Quiz from '../screens/Quiz';
import FinalQuiz from '../screens/FinalQuiz';

export default class Logged extends Component{

    dimensionWindow() {
        let tam = {width: 0, height: 0};
        if (typeof window.innerWidth !== 'undefined')
        {
          tam = {width: window.innerWidth, height: window.innerHeight};
        }
        else if (typeof document.documentElement !== 'undefined'
            && typeof document.documentElement.clientWidth !==
            'undefined' && document.documentElement.clientWidth !== 0)
        {
          tam = {
              width: document.documentElement.clientWidth,
              height: document.documentElement.clientHeight
          };
        }
        else   {
          tam = {
              width: document.getElementsByTagName('body')[0].clientWidth,
              height: document.getElementsByTagName('body')[0].clientHeight
          };
        }
        return tam;
    }

    render(){
        const dimensions = this.dimensionWindow();
        return(
            <BrowserRouter>
                <div style={{height: dimensions.height, position: 'relative'}}>
                    <Routes>
                        <Route exact path="/" element={<WrapperComponent item={Wellcome}/>}/>
                        <Route exact path="/quiz/:id" element={<WrapperComponent item={Quiz}/>}/>
                        <Route exact path="/final/:id" element={<WrapperComponent item={FinalQuiz}/>}/>
                        <Route exact path="/:name_id" element={<WrapperComponent item={Wellcome}/>}/>
                    </Routes>
                    <ToastContainer
                        position="bottom-right"
                        autoClose={false}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </BrowserRouter>
        )
    }
}
