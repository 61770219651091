import React, {Component} from 'react';

export default class Terms extends Component{
  constructor(){
    super();
  }

  render(){
    return(
      <div style={{textAlign: 'justify'}}>
        <p>
          A continuación, se detallan los términos y condiciones para la realización de un background 
          check (revisión de antecedentes) por parte de la empresa Grupo Toledo:
        </p>
        <p>
          <strong>Consentimiento:</strong> El evaluado debe otorgar su consentimiento libre y voluntario para que la 
          empresa realice una revisión de sus antecedentes. Este consentimiento debe ser proporcionado 
          única y exclusivamente por este medio.
        </p>
        <p>
        <strong>Información requerida:</strong> Grupo Toledo requerirá cierta información del solicitante para llevar a 
          cabo la revisión de antecedentes, que incluirá, pero no se limitará a: datos personales como 
          nombres completos, fecha de nacimiento, número de cédula de identidad, dirección actual y 
          previa, información laboral actual y previa, información educativa, y cualquier otra información 
          relevante que sea solicitada por los clientes de Grupo Toledo.
        </p>
        <p>
          <strong>Procedimientos de revisión:</strong> Grupo Toledo utilizará los procedimientos de revisión estándar y 
          aplicables para llevar a cabo la revisión de antecedentes. Esto puede incluir, entre otras cosas, 
          una verificación de antecedentes penales, de empleo y de educación, así como una verificación
          presencial de lugar de residencia con levantamiento fotográfico interno y externo, según 
          requerimiento de la empresa, verificación de referencias personales y profesionales, así como 
          también la obtención de un informe de crédito del solicitante a través de una agencia de 
          informes crediticios autorizada.
        </p>
        <p>
          <strong>Confidencialidad de la información:</strong> Grupo Toledo se compromete a proteger la 
          confidencialidad de toda la información proporcionada por el solicitante durante la revisión de 
          antecedentes. Toda la información recopilada será tratada de manera confidencial y se 
          mantendrá en estricta privacidad.
        </p>
        <p>
          <strong>Tiempo de procesamiento:</strong> Grupo Toledo hará todos los esfuerzos razonables para completar 
          la revisión de antecedentes en un tiempo oportuno. Sin embargo, Grupo Toledo no garantiza un 
          tiempo de procesamiento específico y puede requerir un tiempo adicional para completar la 
          revisión de antecedentes.
        </p>
        <p>
          <strong>Uso de la información:</strong> La información recopilada durante la revisión de antecedentes se 
          utilizará únicamente con fines relacionados con el empleo o la contratación de servicios, y se 
          mantendrá confidencial en todo momento. Grupo Toledo se compromete a cumplir con todas 
          las leyes y regulaciones aplicables en la recopilación y uso de la información de antecedentes.
        </p>
        <p>
          Al otorgar su consentimiento para la revisión de antecedentes, el solicitante reconoce haber 
          leído y entendido estos términos y condiciones y acepta cumplir con ellos en su totalidad.
        </p>
      </div>
    )
  }
}