import React, {Component} from 'react';

export default class Consent extends Component{
  constructor(props){
    super(props);
  }

  render(){
    const { datos } = this.props

    return(
      <div style={{textAlign: 'justify'}}>
        <p>
          Yo, {datos.name+' '+datos.lastname}, con número de identificación {datos.document}, en mi calidad 
          de titular de los datos personales que serán tratados por GRUPO TOLEDO, manifiesto que he sido 
          informado(a) de manera clara, completa y veraz sobre la política de protección de datos 
          personales de GRUPO TOLEDO, y que he comprendido los términos y condiciones que se 
          establecen en ella.
        </p>
        <p>
          Asimismo, autorizo a GRUPO TOLEDO a recolectar, almacenar, procesar, utilizar, transferir y en 
          general, tratar mis datos personales de acuerdo a las finalidades específicas para las que se han 
          solicitado, entre ellas, validación de datos personales, verificación de antecedentes penales, de 
          empleo y de educación, así como una verificación de referencias personales y profesionales, así 
          como también la obtención de un informe de crédito del solicitante a través de una agencia de 
          informes crediticios autorizada
        </p>
        <p>
          También, declaro que entiendo que tengo derecho a solicitar el acceso, rectificación, 
          actualización, oposición y cancelación de mis datos personales, conforme lo establece la 
          normativa legal vigente en Ecuador
        </p>
        <p>
          De igual manera, reconozco que tengo derecho a revocar este consentimiento en cualquier 
          momento, mediante el envío de una solicitud a datospersonales@grupotoledo.ec  
        </p>
        <p>
          Entiendo que la negativa o revocación de este consentimiento podría impedir que GRUPO 
          TOLEDO continúe prestando los servicios o cumpliendo con las finalidades específicas para las 
          que se han solicitado mis datos personales.
        </p>
        <p>
          Por último, manifiesto que he leído y comprendido el presente consentimiento informado, y 
          autorizo de manera voluntaria, sin presión o influencia alguna por parte de GRUPO TOLEDO.
        </p>
      </div>
    )
  }
}