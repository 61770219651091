import React from 'react';
import './App.css';
import Logged from './navigation/Logged';
import Preloader from './components/Preloader';

export default class App extends React.Component {

  constructor(){
    super();
    this.state = {
      loader: false
    }
  }

  componentDidMount(){
    this.setState({loader: true});
  }

  render(){
    const {loader} = this.state;
    if(!loader){
      return (<Preloader/>);
    }else{
      return (<Logged/>);
    }
  }
}